exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-carbon-js": () => import("./../../../src/pages/carbon.js" /* webpackChunkName: "component---src-pages-carbon-js" */),
  "component---src-pages-carbondemo-js": () => import("./../../../src/pages/carbondemo.js" /* webpackChunkName: "component---src-pages-carbondemo-js" */),
  "component---src-pages-details-js": () => import("./../../../src/pages/details.js" /* webpackChunkName: "component---src-pages-details-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

